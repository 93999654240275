* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.beautiful-shadow {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.card {
  transition: all .5s;
}

.card:hover {
  background-color: rgba(17, 12, 46, 0.85) !important;
  cursor: pointer;
}


.card:after {
  content: '🦊';
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.5s;
}

small {
  transition: all 3s;
}

small:hover {
  font-size: 20px !important;
}

.card:hover {
  padding-right: 24px;
  /* padding-left:8px; */
}

.card:hover:after {
  opacity: 1;
  right: 10px;
}

.link_copy {
  transition: 0.5s;
}

.link_copy:hover {
  text-decoration: underline 3px;
  cursor: pointer;
}

.main-container {
  display: flex;
}

main {
  padding: 10px;
  width: 100%;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(0, 7, 61);
  color: white;
  height: 100vh;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo1 {
  font-size: 14px;
  line-height: 0;
  padding: 10px;
  padding-top: 20px;
  color: white;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}

.link_text1 {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}


.PageNotFound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNotFound h1 {
  font-weight: 600;
}